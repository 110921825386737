import React from 'react';

const Spinner = () => (
  <div className="lds-spinner">
    {[...Array(12)].map((_, index) => (
      <div key={index}></div>
    ))}
  </div>
);

const PreLoader = ({ show, message }) => {
  if (!show) return null;

  return (
    <div className="pre-loader">
      <Spinner />
      <p>{message}</p>
    </div>
  );
};

export default PreLoader;