import React from 'react';
import { ReactComponent as HeartIcon } from '../images/svg/heart.svg';
import { ReactComponent as SmileIcon } from '../images/svg/Smile.svg';
import { ReactComponent as SadIcon } from '../images/svg/sad.svg';
import { ReactComponent as GrinIcon } from '../images/svg/grin.svg';
import { ReactComponent as ThumbsUpIcon } from '../images/svg/thumbs-up.svg';
//import { ReactComponent as ThumbsDownIcon } from '../images/svg/thumbs-down.svg';
import { ReactComponent as TongueOutIcon } from '../images/svg/tongue-out.svg';
import { ReactComponent as FaceWithoutMouthIcon } from '../images/svg/face-without-mouth.svg';
import { ReactComponent as FaceWithOpenMouthIcon } from '../images/svg/face-with-open-mouth.svg';
import { ReactComponent as FrowningFaceIcon } from '../images/svg/frowning-face.svg';

const MessageContent = ({ content }) => {
  if (content === ':heart:') {
    return <HeartIcon width="24" height="24" />;
  } else if (content === ':)' || content === ':grinning_face:') {
    return <SmileIcon width="24" height="24" />;
  } else if (content === ':(') {
    return <SadIcon width="24" height="24" />;
  } else if (content === ':D') {
    return <GrinIcon width="24" height="24" />;
  } else if (content === ':+1:' || content === ':thumbs_up:') {
    return <ThumbsUpIcon width="24" height="24" />;
  } else if (content === ':P') {
    return <TongueOutIcon width="24" height="24" />;
  } else if (content === ':face_without_mouth:') {
    return <FaceWithoutMouthIcon width="24" height="24" />;
  } else if (content === ':face_with_open_mouth:') {
    return <FaceWithOpenMouthIcon width="24" height="24" />;
  } else if (content === ':slightly_frowning_face:') {
    return <FrowningFaceIcon width="24" height="24" />;;
  } else if (content === ':clap:') {
    return '👏';
  }
  return <>{content}</>;
};

export default MessageContent;