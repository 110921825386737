import React, { useRef, useEffect } from 'react';
import ChatMessage from './ChatMessage';

const ChatPanel = ({ 
  messages, 
  userId, 
  messageInput, 
  setMessageInput, 
  send, 
  onClose, 
  getAvatarColor 
}) => {
  const messageAreaRef = useRef(null);

  useEffect(() => {
    if (messageAreaRef.current) {
      messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="chat-container">
      <div className="chat-header">
        <div className="chat-header-content">Chat</div>
        <svg onClick={onClose} height="22" width="22" viewBox="0 0 24 24">
          <path d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
        </svg>
      </div>
      <ul id="messageArea" ref={messageAreaRef}>
        {messages.map((message, index) => (
          <ChatMessage 
            key={index}
            message={message}
            isSelf={message.sender === userId}
            getAvatarColor={getAvatarColor}
          />
        ))}
      </ul>
      <form id="messageForm" onSubmit={send}>
        <div className="input-group clearfix">
          <input
            type="text"
            id="message"
            placeholder="Type a message"
            autoComplete="off"
            autoFocus
            className="form-control"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
          />
        </div>
      </form>
    </div>
  );
};

export default ChatPanel;