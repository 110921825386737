import React from 'react';
import ParticipantHeader from './ParticipantHeader';
import ParticipantFilter from './ParticipantFilter';
import ParticipantList from './ParticipantList';

const ParticipantPanel = ({
  onClose,
  filterValue,
  handleFilterChange,
  localUsername,
  userId,
  getAvatarColor,
  raisedHands,
  screenPresenters
}) => {
  return (
    <div className="participants-panel">
      <ParticipantHeader onClose={onClose} />
      <ParticipantFilter 
        filterValue={filterValue}
        handleFilterChange={handleFilterChange}
      />
      <ParticipantList 
        localUsername={localUsername}
        userId={userId}
        getAvatarColor={getAvatarColor}
        raisedHands={raisedHands}
        screenPresenters={screenPresenters}
      />
    </div>
  );
};

export default ParticipantPanel;