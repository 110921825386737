import React from 'react';

const RequestToTalkButton = ({ disabled, onClick }) => {
  return (
    <div 
      className={`request-to-talk-btn ${disabled ? 'request-to-talk-btn-disabled' : ''}`}
      onClick={disabled ? null : onClick}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="17.826" height="23.981" viewBox="0 0 17.826 23.981">
        <path id="mute"
          d="M10.766,15.531l1.2-1.2a4.942,4.942,0,0,0,1.03.118c2.025,0,3.672-1.184,3.672-2.639V9.63l1.5-1.5v3.681c0,2.282-2.32,4.138-5.171,4.138a6.183,6.183,0,0,1-2.227-.42ZM5.279,18.886,21.132,3.033a.749.749,0,1,0-1.06-1.06L18.156,3.89c-.153-2.173-2.41-3.9-5.163-3.9-2.852,0-5.171,1.856-5.171,4.138v7.687a3.471,3.471,0,0,0,.552,1.859L6.72,15.325a4.914,4.914,0,0,1-1.06-3,.749.749,0,0,0-1.5,0,6.415,6.415,0,0,0,1.5,4.066l-1.44,1.44a.749.749,0,0,0,1.06,1.06ZM9.321,4.125c0-1.455,1.647-2.639,3.672-2.639s3.672,1.184,3.672,2.639V5.38L9.475,12.571a1.954,1.954,0,0,1-.154-.758V4.125ZM13.743,19.6v2.872h1.978a.749.749,0,1,1,0,1.5H10.265a.749.749,0,0,1,0-1.5h1.978V19.6a9.968,9.968,0,0,1-4.271-1.276l1.1-1.1a8.727,8.727,0,0,0,3.917.907c4.043,0,7.332-2.6,7.332-5.8a.749.749,0,1,1,1.5,0C21.825,16.139,18.264,19.282,13.743,19.6Z"
          transform="translate(-4 0.013)" fill="#fff" />
      </svg>
      <span>Request to talk</span>
    </div>
  );
};

export default RequestToTalkButton;