import React from 'react';

const AcceptedTalkRequestToaster = ({ message }) => {
    return (
        <div className="toaster-wrapper">
            <div className="toaster-content-area">
                <div className="toaster-title">{message}</div>
                <div className="toaster-content">
                    <div className="toaster-content-text">
                        <span>You will be able to talk in a while. Please wait.</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AcceptedTalkRequestToaster;