import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import InfoContent from './InfoContent';

const InfoButton = ({ 
  infoContentShow, 
  setInfoContentShow, 
  handleMouseEnter, 
  handleMouseLeave, 
  roomName, 
  bigMeetingInvitationURL 
}) => {
  const infoRef = useRef(null);

  return (
    <div 
      className="information-preview" 
      ref={infoRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div 
        aria-label="Information" 
        className="toolbox-button" 
        role="button"
        tabIndex="0" 
        onClick={() => setInfoContentShow(!infoContentShow)}
      >
        <div>
          <div className="toolbox-icon">
            <div className="jitsi-icon jitsi-icon-default">
              <svg height="22" width="22" viewBox="0 0 28 28">
                <g data-name="Group 3901" transform="translate(-95 -132)">
                  <rect 
                    data-name="Rectangle 2490" 
                    width="28"
                    height="28" 
                    rx="8"
                    transform="translate(95 132)"
                    fill="#c7ccd9"
                  />
                  <g data-name="Group 3900">
                    <path 
                      data-name="Path 30"
                      d="M110.651 139.594a1.488 1.488 0 11-1.5-1.476 1.489 1.489 0 011.5 1.476zm-.351 13.961h-2.273v-9.862h2.273z"
                      fill="#4f555e"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
      {infoContentShow && (
        <InfoContent
          closeInfoContent={() => setInfoContentShow(false)}
          roomName={roomName}
          bigMeetingInvitationURL={bigMeetingInvitationURL}
        />
      )}
    </div>
  );
};

InfoButton.propTypes = {
  infoContentShow: PropTypes.bool.isRequired,
  setInfoContentShow: PropTypes.func.isRequired,
  handleMouseEnter: PropTypes.func.isRequired,
  handleMouseLeave: PropTypes.func.isRequired,
  roomName: PropTypes.string.isRequired,
  bigMeetingInvitationURL: PropTypes.string.isRequired,
};

export default InfoButton;
