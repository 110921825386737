import { useDispatch, useSelector } from 'react-redux';
import { addGuest, deleteGuest, addHost, deleteHost, addPresenter, deletePresenter } from '../../redux/Participants/actions';

const useMessageHandler = (currentUserId) => {
    const dispatch = useDispatch();
    const guests = useSelector(state => state.participants.guests);
    const hosts = useSelector(state => state.participants.hosts);
    const presenters = useSelector(state => state.participants.presenters);
    const emojiMap = {
        ':thumbs_up:': '👍',
        ':clap:': '👏',
        ':grinning_face:': '😀',
        ':face_with_open_mouth:': '😮',
        ':slightly_frowning_face:': '🙁',
        ':face_without_mouth:': '😶'
    };
    
    const checkIfUserExistsAlready = (userId) => {
        if (guests[userId] || hosts[userId] || presenters[userId]) {
            return true;
        }
        return false;
    };

    const handleJoinMessage = (message, setJoinTasksCompleted) => {
        if (!message.sender.startsWith("mp-") && !message.sender.startsWith("wt-")) {
            if (!checkIfUserExistsAlready(message.sender)) {
                try {
                    if (message.fromConvay && message.isHost) {
                        dispatch(addHost(message.sender, message.name));
                    } else if (message.fromConvay && !message.isHost) {
                        dispatch(addPresenter(message.sender, message.name));
                    } else if (!message.fromConvay && !message.isHost) {
                        dispatch(addGuest(message.sender, message.name));
                    }
                } catch (error) {
                    console.error("Error joining user:", error);
                }
            }
        }
        setJoinTasksCompleted(true);
    };

    const handleExistingUsersJoinMessage = (message, setJoinTasksCompleted) => {
        message.users.forEach(user => {
            if (!user.sender.startsWith("mp-") && !user.sender.startsWith("wt-")) {
                if (!checkIfUserExistsAlready(user.sender)) {
                    try {
                        if (user.fromConvay && user.isHost) {
                            dispatch(addHost(user.sender, user.name));
                        } else if (user.fromConvay && !user.isHost) {
                            dispatch(addPresenter(user.sender, user.name));
                        } else if (!user.fromConvay && !user.isHost) {
                            dispatch(addGuest(user.sender, user.name));
                        }
                    } catch (error) {
                        console.error("Error joining user:", error);
                    }
                }
            }
        });
        setJoinTasksCompleted(true);
    };

    const handleLeaveMessage = (message, isRecent, setRecent) => {
        if (isRecent) {
            setRecent(false);
        }
        let sender = message.sender;
        if (sender.startsWith("mp-") || sender.startsWith("wt-")) {
            sender = sender.replace("mp-", "").replace("wt-", "");
        }
        dispatch(deleteHost(sender));
        dispatch(deletePresenter(sender));
        dispatch(deleteGuest(sender));
    };

    const handleChatMessage = (message, setNewMessage, setNewMessageSender, setMessageToasterShow, setMessages, setNewMessageCounter) => {
        let processedContent = message.content;

        const convertEmojis = (text) => {
            return text.replace(/:([\w-]+):/g, (match, p1) => emojiMap[`:${p1}:`] || match);
        };
        
        try {
            const parsedContent = JSON.parse(message.content);
            if (parsedContent.type === 'SEND_REACTION') {
                return;
            }
            processedContent = parsedContent;
        } catch (error) {
            processedContent = convertEmojis(message.content);
        }

        setMessages((prevMessages) => [...prevMessages, {...message, content: processedContent}]);
        if (message.sender !== currentUserId) {
            setNewMessage(processedContent);
            setNewMessageSender(message.name);
            setMessageToasterShow(true);
            setNewMessageCounter(prevCounter => prevCounter + 1);
        }
    };

    const handleRaiseHandMessage = (message, hostsRef, presentersRef, setRaisedHands) => {
        const hostUser = Object.entries(hostsRef.current).find(([id, _]) => {
            const cleanId = id.replace(/^(mp-|wt-)/, '');
            return cleanId === message.sender;
        });

        const presenter = Object.entries(presentersRef.current).find(([id, _]) => {
            const cleanId = id.replace(/^(mp-|wt-)/, '');
            return cleanId === message.sender;
        });

        if (hostUser || presenter) {
            setRaisedHands(prev => new Set(prev).add(message.sender));
        }
    };

    const handleLowerHandMessage = (message, setRaisedHands) => {
        setRaisedHands(prev => {
            const updatedHands = new Set(prev);
            updatedHands.delete(message.sender);
            return updatedHands;
        });
    };

    const handleRaisedHandsListMessage = (message, joinTasksCompletedRef, hostsRef, presentersRef, setRaisedHands, onMessageReceived, payload) => {
        if (joinTasksCompletedRef.current === false) {
            setTimeout(() => onMessageReceived(payload), 100);
            return;
        }
        try {
            const raisedHandUsers = JSON.parse(message.content);
            setRaisedHands(prevRaisedHands => {
                const updatedRaisedHands = new Set(prevRaisedHands);
                raisedHandUsers.forEach(user => updatedRaisedHands.add(user));
                return updatedRaisedHands;
            });
        } catch (error) {
            console.error('Error parsing raised hands list:', error);
        }
    };

    /**
     * If a meeting panel user starts sharing their screen, add them to the screen presenters list.
     * @param {*} message => Message coming from big meeting backend
     * @param {*} setScreenPresenters => Function to set the screen presenters list
     */
    const handleStartScreenShare = (message, setScreenPresenters) => {
        setScreenPresenters(prev => new Set(prev).add(message.sender));
    }


    /**
     * If a meeting panel user stops sharing their screen, remove them from the screen presenters list.
     * @param {*} message => Message coming from big meeting backend
     * @param {*} setScreenPresenters => Function to set the screen presenters list
     */
    const handleStopScreenShare = (message, setScreenPresenters) => {
        setScreenPresenters(prev => {
            const updatedScreenPresenters = new Set(prev);
            updatedScreenPresenters.delete(message.sender);
            return updatedScreenPresenters;
        });
    }

    /**
     * If joined while a user is sharing their screen, big meeting backend sends a list of screen presenters before joining. 
     * Wait till all join tasks are completed before processing
     * @param {*} message => Message coming from big meeting backend
     * @param {*} setScreenPresenters => Function to set the screen presenters list
     */
    const handleScreenShareList = (message, joinTasksCompletedRef, setScreenPresenters, onMessageReceived, payload) => {
        if (joinTasksCompletedRef.current === false) {
            setTimeout(() => onMessageReceived(payload), 100);
            return;
        }
        const screenPresenterIds = message.content.split(',');
        screenPresenterIds.forEach(id => {
            setScreenPresenters(prev => new Set(prev).add(id));
        });
    }

    /**
     *  
     * @param {*} message => Message coming from big meeting backend
     */
    const handleInformAboutNewHost = (message) => {
        let sender = message.sender;
        if (sender.startsWith("mp-") || sender.startsWith("wt-")) {
            sender = sender.replace("mp-", "").replace("wt-", "");
        }
        dispatch(deletePresenter(sender));
        dispatch(addHost(sender, message.name));
    }

    return {
        handleJoinMessage,
        handleExistingUsersJoinMessage,
        handleLeaveMessage,
        handleChatMessage,
        handleRaiseHandMessage,
        handleLowerHandMessage,
        handleRaisedHandsListMessage,
        handleStartScreenShare,
        handleStopScreenShare,
        handleScreenShareList,
        handleInformAboutNewHost
    };
};

export default useMessageHandler;