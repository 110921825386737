import React, {useState, useEffect} from 'react';
import {IconChat} from "../../images/svg/chat.svg";

function Toaster(props) {

    const [showToaster, setShowToaster] = useState(true);


    useEffect(() => {
        setTimeout(() => {
            toasterClose();
        }, 4000);
    }, [showToaster]);

    function toasterClose() {
        setShowToaster(false);
        props.closeToaster();
    }


    return (
        <div className="toaster-wrapper">
            <span className="toaster-close">
                <svg onClick={() => toasterClose()} height="22" width="22" viewBox="0 0 24 24">
                    <path
                        d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
                    </svg>
                </span>

            <div className="toaster-icon">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <title>chat-unread</title>
                    <path
                        d="M18 8.016v-2.016h-12v2.016h12zM14.016 14.016v-2.016h-8.016v2.016h8.016zM6 9v2.016h12v-2.016h-12zM20.016 2.016c1.078 0 1.969 0.891 1.969 1.969v12c0 1.078-0.891 2.016-1.969 2.016h-14.016l-3.984 3.984v-18c0-1.078 0.891-1.969 1.969-1.969h16.031z"></path>
                </svg>
            </div>

            <div className="toaster-content-area">
                <div className="toaster-title">{props.title}</div>
                <div className="toaster-content">{props.content}</div>
            </div>
        </div>
    )
}

export default Toaster;