import React, {useState, useEffect, useRef} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import '../../ChatApp.css';
import StreamPlayer from '../../StreamPlayer';
import RequestTalkToaster from "../Toaster/RequestTalkToaster";
import HostRequestTalkToaster from "../Toaster/HostRequestTalkToaster";
import {sendToMp} from '../../App';
import AcceptRequestNotification from './AcceptRequestNotification';
import DeclineRequestNotification from './DeclineRequestNotification';
import InviteDialog from './InviteDialog';
import AcceptedTalkRequestToaster from './AcceptedTalkRequestToaster';
import LeaveDialog from './LeaveDialog';
import MessageToaster from './MessageToaster';
import OverlayLoader from './OverlayLoader';
import PreLoader from './PreLoader';
import ChatPanel from './ChatPanel';
import ParticipantPanel from './ParticipantPanel';
import EmojiReaction from './EmojiReaction';
import RequestToTalkButton from './RequestToTalkButton';
import ChatButton from './ChatButton';
import HangupButton from './HangupButton';
import InfoButton from './InfoButton';
import ParticipantButton from './ParticipantButton';
import useMessageHandler from '../functions/messageHandlers';
import {useDispatch, useSelector} from 'react-redux';
import {deleteGuest} from '../../redux/Participants/actions';

function Layout({userId, username, updateCredentials, isRecent, setRecent}) {
    let {roomName} = useParams();
    roomName = roomName ? roomName : "public";

    let leaveLink = `${process.env.REACT_APP_FEEDBACK_URL}?meetingId=${roomName}`
    let homePageLink = process.env.REACT_APP_HOMEPAGE_URL;

    const navigate = useNavigate();
    const messageAreaRef = useRef(null);
    const guests = useSelector(state => state.participants.guests);
    const hosts = useSelector(state => state.participants.hosts);
    const presenters = useSelector(state => state.participants.presenters);
    const [localUsername, setLocalUsername] = useState(username || '');
    const [streamUrl, setStreamUrl] = useState('');
    const [stompClient, setStompClient] = useState(null);
    const [messageInput, setMessageInput] = useState('');
    const [messages, setMessages] = useState([]);
    const [connecting, setConnecting] = useState(false);
    const [chatPanel, setChatPanel] = useState(false);
    const [participantPanel, setParticipantPanel] = useState(false);
    const [filterValue, setFilterValue] = useState('');
    const [requestTalkButtonDisabled, setRequestTalkButtonDisabled] = useState(false);
    const [requestTalkToasterShow, setRequestTalkToasterShow] = useState(false);
    const [hostRequestTalkToasterShow, setHostRequestTalkToasterShow] = useState(false);
    const [messageToasterShow, setMessageToasterShow] = useState(false);
    const [infoContentShow, setInfoContentShow] = useState(false);
    const [showPreloader, setShowPreloader] = useState(false);
    const [showReactionPanel, setShowReactionPanel] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [buttonText, setButtonText] = useState("Join Meeting");
    const [overlay, setOverlay] = useState(true);
    const [newMessageCounter, setNewMessageCounter] = useState(0);
    const [newMessage, setNewMessage] = useState('');
    const [newMessageSender, setNewMessageSender] = useState('');
    const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState(false);
    const [talkRequestAccepted, setTalkRequestAccepted] = useState(false);
    const [talkRequestDeclined, setTalkRequestDeclined] = useState(false);
    const [invitedToTalkUserName, setInvitedToTalkUserName] = useState('');
    const [invitedToTalkUserId, setInvitedToTalkUserId] = useState('');
    const [inviterId, setInviterId] = useState('');
    const [inviterName, setInviterName] = useState('');
    const [inviteDialogVisible, setInviteDialogVisible] = useState(false);
    const [inviteAcceptanceDialogVisible, setInviteAcceptanceDialogVisible] = useState(false);
    const [usernameError, setUsernameError] = useState('');
    const infoRef = useRef(null);
    const hoverTimeoutRef = useRef(null);
    const [raisedHands, setRaisedHands] = useState(new Set());
    const [joinTasksCompleted, setJoinTasksCompleted] = useState(false);
    const [screenPresenters, setScreenPresenters] = useState(new Set());
    const [isAutoSubmit, setIsAutoSubmit] = useState(false);

    const colors = [
        '#F6C5BE',
        '#FFE6C7',
        '#FEF1D1',
        '#B9E4D0',
        '#C6F3DE',
        '#C9DAF8',
        '#E4D7F5',
        '#FFE6C7',
    ];

    const dispatch = useDispatch();

    const {
        handleJoinMessage,
        handleExistingUsersJoinMessage,
        handleLeaveMessage,
        handleChatMessage,
        handleRaiseHandMessage,
        handleLowerHandMessage,
        handleRaisedHandsListMessage,
        handleStartScreenShare,
        handleStopScreenShare,
        handleScreenShareList,
        handleInformAboutNewHost
    } = useMessageHandler(userId);

    useEffect(() => {
        if (stompClient) {
            stompClient.connect({}, onConnected, onError);
        }
    }, [stompClient]);

    useEffect(() => {
        let timer;
        if (talkRequestDeclined) {
            timer = setTimeout(() => {
                setTalkRequestDeclined(false);
            }, 10000);
        }
        return () => clearTimeout(timer);
    }, [talkRequestDeclined]);

    useEffect(() => {
        const savedCredentials = JSON.parse(localStorage.getItem(`room-${roomName}`));
        if (savedCredentials) {
            setLocalUsername(savedCredentials.username || '');
            setIsAutoSubmit(true);
        }
    }, [roomName]);

    useEffect(() => {
        if (isAutoSubmit && localUsername) {
            connect();
        }
    }, [isAutoSubmit, localUsername]);

    const saveRoomCredentials = (username) => {
        const roomCredentials = {username};
        localStorage.setItem(`room-${roomName}`, JSON.stringify(roomCredentials));
        updateCredentials(username, roomName);
    };

    const connect = (event) => {
        if (localUsername && roomName) {
            if (localUsername.trim() === '') {
                setUsernameError('Please enter a valid username');
                return;
            }
            const storedCredentials = JSON.parse(localStorage.getItem(`room-${roomName}`));

            if (!storedCredentials) {
                saveRoomCredentials(localUsername);
            }

            setIsClicked(true)
            setButtonText("Loading...")
            const socket = new SockJS(`${process.env.REACT_APP_BIG_MEETING_BACKEND_URL}/websocket`, null, {
                transports: ['websocket', 'xhr-polling']
            });
            setStompClient(Stomp.over(socket));
        } else {
            alert("Invalid username");
        }
    };

    const handleLogin = (event) => {
        event.preventDefault();
        if (localUsername.trim() !== '') {
            connect();
        } else {
            setUsernameError('Please enter a valid username');
        }
    };

    const onConnected = () => {
        setConnecting(true);
        stompClient.subscribe("/user/" + userId + "/topic/" + roomName, onMessageReceived);
        stompClient.subscribe("/topic/" + roomName, onMessageReceived);
        if (isRecent) {
            stompClient.send("/app/chat.register", {}, JSON.stringify({
                sender: userId,
                name: localUsername,
                type: "JOIN",
                room: roomName,
                content: "recent"
            }));
        } else {
            stompClient.send("/app/chat.register", {}, JSON.stringify({
                sender: userId,
                name: localUsername,
                type: "JOIN",
                room: roomName
            }));
        }
    };

    const onError = (error) => {
        console.error("WebSocket connection error:", error);
        setTimeout(() => {
            setMessages([])
            reconnect();
        }, 4000);
    };

    const reconnect = () => {
        const socket = new SockJS(`${process.env.REACT_APP_BIG_MEETING_BACKEND_URL}/websocket`, null, {
            transports: ['websocket', 'xhr-polling']
        });
        const newStompClient = Stomp.over(socket);
        setStompClient(newStompClient);
    };

    const send = (event) => {
        event.preventDefault();

        if (messageInput && stompClient) {
            const chatMessage = {
                sender: userId,
                name: localUsername,
                content: messageInput,
                type: "CHAT",
                room: roomName,
            };
            stompClient.send("/app/chat.send", {}, JSON.stringify(chatMessage));
            setMessageInput('');
        }
    };

    const handleParticipantButtonClick = () => {
        setParticipantPanel(!participantPanel);
        setChatPanel(false);
    };

    const participantCount = Object.entries(guests).length + Object.entries(hosts).length + Object.entries(presenters).length;


    const requestTalk = () => {
        setRequestTalkToasterShow(true);


        setRequestTalkButtonDisabled(true);

        setTimeout(() => {
            setRequestTalkButtonDisabled(false);
        }, 30000);

        if (stompClient) {
            const chatMessage = {
                sender: userId,
                name: localUsername,
                content: '',
                type: "WANT_TO_TALK",
                room: roomName,
            };
            stompClient.send("/app/chat.want.to.talk", {}, JSON.stringify(chatMessage));
        }
    }

    const hostsRef = useRef([]);
    const presentersRef = useRef([]);
    const joinTasksCompletedRef = useRef(joinTasksCompleted);

    useEffect(() => {
        hostsRef.current = hosts;
    }, [hosts]);

    useEffect(() => {
        presentersRef.current = presenters;
    }, [presenters]);

    useEffect(() => {
        joinTasksCompletedRef.current = joinTasksCompleted;
    }, [joinTasksCompleted]);

    const onMessageReceived = (payload) => {
        const message = JSON.parse(payload.body);
        console.log("message ::", message);

        const messageHandlers = {
            JOIN: () => handleJoinMessage(message, setJoinTasksCompleted),
            USER_LIST: () => handleExistingUsersJoinMessage(message, setJoinTasksCompleted),
            LEAVE: () => handleLeaveMessage(message, isRecent, setRecent),
            CHAT: () => handleChatMessage(message, setNewMessage, setNewMessageSender, setMessageToasterShow, setMessages, setNewMessageCounter),
            URL: () => setStreamUrl(message.content),
            WEBINAR_PARTICIPANT_LEAVE_PROVISION: () => confirmLeaveMeeting(), //confirmLeaveMeeting is originally needed to leave the meeting from guest end, however, kicking a guest requires same functionality, that is why the same function is used here
            WEBINAR_PARTICIPANT_SEND_TO_MP_PROVISION: () => {
                if (stompClient) {
                    const chatMessage = {
                        sender: userId,
                        name: localUsername,
                        content: '',
                        type: "LEAVE_WEBINAR",
                        room: roomName,
                    };
                    stompClient.disconnect(() => {
                        dispatch(deleteGuest(userId));
                        sendToMp(message.content);
                    });
                    stompClient.send("/app/chat.leave.webinar", {}, JSON.stringify(chatMessage));
                }
            },
            REQUEST_TO_TALK_DECLINED: () => setTalkRequestDeclined(true),
            REQUEST_TO_TALK_ACCEPTED: () => setTalkRequestAccepted(true),
            INVITE_BIG_MEETING_USER_TO_TALK: () => {
                setInvitedToTalkUserName(message.name);
                setInvitedToTalkUserId(message.sender);
                setInviterId(message.from);
                setInviterName(message.fromName);
                setInviteDialogVisible(true);
            },
            END_BIG_MEETING: endMeeting,
            RAISE_HAND: () => handleRaiseHandMessage(message, hostsRef, presentersRef, setRaisedHands),
            LOWER_HAND: () => handleLowerHandMessage(message, setRaisedHands),
            RAISED_HANDS_LIST: () => handleRaisedHandsListMessage(message, joinTasksCompletedRef, hostsRef, presentersRef, setRaisedHands, onMessageReceived, payload),
            START_SCREEN_SHARE: () => handleStartScreenShare(message, setScreenPresenters),
            STOP_SCREEN_SHARE: () => handleStopScreenShare(message, setScreenPresenters),
            SCREEN_SHARE_LIST: () => handleScreenShareList(message, joinTasksCompletedRef, setScreenPresenters, onMessageReceived, payload),
            INFORM_ABOUT_NEW_HOST: () => handleInformAboutNewHost(message),
        };

        const handler = messageHandlers[message.type];
        if (handler) {
            handler();
        }
    };

    const getAvatarColor = (messageSender) => {
        let hash = 0;
        for (let i = 0; i < messageSender.length; i++) {
            hash = 31 * hash + messageSender.charCodeAt(i);
        }
        const index = Math.abs(hash % colors.length);
        return colors[index];
    };

    useEffect(() => {
        if (messageAreaRef.current) {
            messageAreaRef.current.scrollTop = messageAreaRef.current.scrollHeight;
        }
    }, [messages]);

    const handleFilterChange = e => {
        setFilterValue(e.target.value);
    };

    const handleHangupClick = () => {
        setIsLeaveDialogOpen(true);
    };

    const confirmLeaveMeeting = () => {
        setIsLeaveDialogOpen(false);
        // Clear localStorage for this room
        localStorage.removeItem(`room-${roomName}`);
        if (stompClient) {
            const chatMessage = {
                sender: userId,
                name: localUsername,
                content: '',
                type: "LEAVE_BIG_MEETING",
                room: roomName,
            };
            stompClient.send("/app/chat.leave.webinar", {}, JSON.stringify(chatMessage), () => {
                stompClient.disconnect(() => {
                    setIsLeaveDialogOpen(false);
                });
            });
        }
        window.location.href = leaveLink;
    };

    useEffect(() => {
        const handlePopState = (event) => {
            navigate(homePageLink, {replace: true});
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate, homePageLink]);

    const endMeeting = () => {
        console.log("End meeting");
        if (stompClient) {
            stompClient.disconnect(() => {
                handleEndMeeting();
            });
        } else {
            handleEndMeeting();
        }
    };

    const handleEndMeeting = () => {
        window.history.pushState(null, '', homePageLink);
        window.history.replaceState(null, '', homePageLink);
        window.location.href = leaveLink;
    };

    const cancelLeaveMeeting = () => {
        setIsLeaveDialogOpen(false);
    };

    const acceptRequestToTalk = () => {
        if (stompClient) {
            const chatMessage = {
                sender: invitedToTalkUserId,
                name: invitedToTalkUserName,
                from: inviterId,
                content: '',
                type: "INVITATION_TO_TALK_ACCEPTED",
                room: roomName
            };
            console.log(chatMessage);
            stompClient.send("/app/chat.invitation.to.talk.accepted", {}, JSON.stringify(chatMessage));
            setInviteDialogVisible(false);
            setInviteAcceptanceDialogVisible(true);
        }
    };

    const declineRequestToTalk = () => {
        if (stompClient) {
            const chatMessage = {
                sender: invitedToTalkUserId,
                name: invitedToTalkUserName,
                from: inviterId,
                content: '',
                type: "INVITATION_TO_TALK_DECLINED",
                room: roomName
            };
            console.log(chatMessage);
            stompClient.send("/app/chat.invitation.to.talk.declined", {}, JSON.stringify(chatMessage));
            setInviteDialogVisible(false);
        }
    };

    const handleEmojiClick = (emoji) => {
        setShowReactionPanel(false);
        if (stompClient) {
            const chatMessage = {
                sender: userId,
                name: localUsername,
                content: emoji,
                type: "CHAT",
                room: roomName,
            };
            stompClient.send("/app/chat.send", {}, JSON.stringify(chatMessage));
        }
    };

    const handlePlayerReady = () => {
        setOverlay(true);
        setTimeout(() => {
            setOverlay(false);
        }, 10000);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (infoRef.current && !infoRef.current.contains(event.target)) {
                setInfoContentShow(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [infoRef]);

    const handleMouseEnter = () => {
        clearTimeout(hoverTimeoutRef.current);
        setInfoContentShow(true);
    };

    const handleMouseLeave = () => {
        hoverTimeoutRef.current = setTimeout(() => {
            setInfoContentShow(false);
        }, 300);
    };

    const handleAcceptInvite = () => {
        acceptRequestToTalk(invitedToTalkUserId, invitedToTalkUserName, inviterId);
    };

    const handleDeclineInvite = () => {
        declineRequestToTalk(invitedToTalkUserId, invitedToTalkUserName, inviterId);
    };

    const handleConfirmLeave = () => {
        confirmLeaveMeeting();
        setIsLeaveDialogOpen(false);
    };

    const handleCancelLeave = () => {
        cancelLeaveMeeting();
        setIsLeaveDialogOpen(false);
    };

    const handleCloseMessageToaster = () => {
        setMessageToasterShow(false);
    };

    const handleCloseChatPanel = () => {
        setChatPanel(false);
        setNewMessageCounter(0);
        setMessageToasterShow(false);
    };

    const handleChatButtonClick = () => {
        setChatPanel(!chatPanel);
        setParticipantPanel(false);
        setNewMessageCounter(0);
        setMessageToasterShow(false);
    };

    let requestToTalkAcceptedMessage = `Your request to talk has been accepted by the host`;
    let requestToTalkDeclinedMessage = `Request Declined`;

    return (
        <div className="wrapper">
            {!connecting && (
                <div id="username-page">
                    <div className="username-page-container">
                        <h1 className="title">Bring everyone together with Convay</h1>
                        <form id="usernameForm" onSubmit={handleLogin}>
                            <div className="form-group">
                                <div className={'form-label'}>Name</div>
                                <input
                                    type="text"
                                    id="name"
                                    placeholder="Enter your name"
                                    autoComplete="off"
                                    className={`form-control ${usernameError ? 'error' : ''}`}
                                    autoFocus
                                    value={localUsername}
                                    onChange={(e) => {
                                        const newUsername = e.target.value;
                                        setLocalUsername(newUsername);
                                        if (newUsername.trim() === '') {
                                            setUsernameError('Please enter a valid username');
                                        } else {
                                            setUsernameError('');
                                        }
                                    }}
                                />
                                {usernameError && <span className="error-message">{usernameError}</span>}
                            </div>
                            <div className="form-group">
                                <button
                                    disabled={isClicked}
                                    type="submit"
                                    className="username-submit"
                                >
                                    {buttonText}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {localUsername && connecting && (
                <div id="chat-page">

                    <div
                        className={(overlay ? 'video-container overlay' : participantPanel || chatPanel ? 'video-container panel-open' : 'video-container')}>
                        <StreamPlayer streamUrl={streamUrl} chat={chatPanel} participant={participantPanel}
                                      onPlayerReady={handlePlayerReady}/>
                    </div>


                    {participantPanel && (
                        <ParticipantPanel
                            onClose={() => setParticipantPanel(false)}
                            filterValue={filterValue}
                            handleFilterChange={handleFilterChange}
                            localUsername={localUsername}
                            userId={userId}
                            getAvatarColor={getAvatarColor}
                            raisedHands={raisedHands}
                            screenPresenters={screenPresenters}
                        />
                    )}

                    {chatPanel && (
                        <ChatPanel
                            messages={messages}
                            userId={userId}
                            messageInput={messageInput}
                            setMessageInput={setMessageInput}
                            send={send}
                            onClose={handleCloseChatPanel}
                            getAvatarColor={getAvatarColor}
                        />
                    )}

                    <div className="toolbox visible"
                         style={{width: participantPanel || chatPanel ? "calc(100% - 315px)" : "100%"}}>
                        <div className="toolbox-content">
                            <div className="toolbox-content-wrapper">
                                <div className="first-portion">

                                    <div className="first-portion-2">
                                        <div className="first-portion-3">
                                            <InfoButton
                                                infoContentShow={infoContentShow}
                                                setInfoContentShow={setInfoContentShow}
                                                handleMouseEnter={handleMouseEnter}
                                                handleMouseLeave={handleMouseLeave}
                                                roomName={roomName}
                                                bigMeetingInvitationURL={process.env.REACT_APP_BIG_MEETING_INVITATION_URL}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="second-portion">
                                    <div className="base-middle-part">
                                        <div className="first-part-middle-part common-background-for-middle-part">
                                            <RequestToTalkButton
                                                disabled={requestTalkButtonDisabled}
                                                onClick={requestTalk}
                                            />
                                        </div>
                                        <div className="third-part-middle-part common-background-for-middle-part">
                                            <ParticipantButton
                                                onClick={handleParticipantButtonClick}
                                                participantCount={participantCount}
                                            />
                                            <ChatButton
                                                onClick={handleChatButtonClick}
                                                newMessageCounter={newMessageCounter}
                                                chatPanel={chatPanel}
                                            />
                                            <EmojiReaction
                                                handleEmojiClick={handleEmojiClick}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="third-portion">
                                    <HangupButton onClick={handleHangupClick}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <PreLoader
                        show={showPreloader}
                        message={"The host has granted your request to talk. Admitting you shortly."}
                    />
                    <OverlayLoader show={overlay}/>
                </div>

            )
            }

            {requestTalkToasterShow && (
                <RequestTalkToaster
                    closeToaster={() => {
                        setRequestTalkToasterShow(false);
                    }}
                />
            )}

            {hostRequestTalkToasterShow && (
                <HostRequestTalkToaster
                    closeToaster={() => {
                        setHostRequestTalkToasterShow(false);
                    }}
                />
            )}

            <MessageToaster
                show={messageToasterShow && !chatPanel}
                onClose={handleCloseMessageToaster}
                sender={newMessageSender}
                message={newMessage}
            />


            <LeaveDialog
                isOpen={isLeaveDialogOpen}
                onConfirm={handleConfirmLeave}
                onCancel={handleCancelLeave}
            />

            {talkRequestAccepted && (
                <AcceptedTalkRequestToaster
                    message={requestToTalkAcceptedMessage}
                />
            )}

            {talkRequestDeclined && (
            <DeclineRequestNotification
                message={requestToTalkDeclinedMessage}
                closeToaster={() => {
                    setTalkRequestDeclined(false);
                }}
            />
            )}

            {inviteDialogVisible && (
                <InviteDialog
                    inviterName={inviterName}
                    onAccept={handleAcceptInvite}
                    onDecline={handleDeclineInvite}/>
            )}

            {inviteAcceptanceDialogVisible && (
                <AcceptRequestNotification/>
            )}

        </div>
    )
}

export default Layout;
