import { ADD_GUEST, DELETE_GUEST, ADD_HOST, DELETE_HOST, ADD_PRESENTER, DELETE_PRESENTER} from "./actionTypes.ts";

const initialState = {
    guests: {},
    hosts: {},
    presenters: {},
};

export const participantsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_GUEST:
            return {
                ...state,
                guests: { ...state.guests, [action.payload.userId]: action.payload.userName },
            };
        case DELETE_GUEST:
            const { [action.payload]: _, ...remainingGuests } = state.guests;
            return { ...state, guests: remainingGuests };
        case ADD_HOST:
            return {
                ...state,
                hosts: { ...state.hosts, [action.payload.userId]: action.payload.userName },
            };
        case DELETE_HOST:
            const { [action.payload]: __, ...remainingHosts } = state.hosts;
            return { ...state, hosts: remainingHosts };
        case ADD_PRESENTER:
            return {
                ...state,
                presenters: { ...state.presenters, [action.payload.userId]: action.payload.userName },
            };
        case DELETE_PRESENTER:
            const { [action.payload]: ___, ...remainingPresenters } = state.presenters;
            return { ...state, presenters: remainingPresenters };
        default:
            return state;
    }
};