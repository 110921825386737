import React from 'react';
import PropTypes from 'prop-types';

const ParticipantButton = ({ onClick, participantCount }) => {
  return (
    <div className="participant-effect" title="Participants" onClick={onClick}>
      <div className="toolbar-button-with-badge">
        <div
          aria-pressed="false"
          aria-disabled="false"
          aria-label="Participants"
          className="toolbox-button"
          tabIndex="0"
          role="button"
        >
          <div>
            <div className="toolbox-icon">
              <div className="jitsi-icon jitsi-icon-default">
                <svg height="22" width="22" viewBox="0 0 30.737 23.582">
                  <g data-name="Group 4021">
                    <g data-name="Group 197">
                      <path
                        data-name="Path 51"
                        d="M21.104 22.78v-2.956c0-2.673-1.752-5.075-3.584-6.674l-3.412-2.765a5.2 5.2 0 001.047-7.318 5.286 5.286 0 00-7.367-1.04 5.208 5.208 0 000 8.357L4.371 13.15c-1.842 1.6-3.575 4-3.575 6.674v2.956z"
                        fill="none"
                        stroke="#c7ccd9"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                      ></path>
                    </g>
                    <g data-name="Group 198">
                      <path
                        data-name="Path 52"
                        d="M24.132 22.781h5.806v-2.956c0-2.673-1.711-5.075-3.5-6.674l-3.336-2.765a5.28 5.28 0 001.039-7.314A5.091 5.091 0 0017.7 1.54"
                        fill="none"
                        stroke="#c7ccd9"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.6"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <span className="badge-counter">
          <span className="badge-counter-text">{participantCount}</span>
        </span>
      </div>
    </div>
  );
};

ParticipantButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  participantCount: PropTypes.number.isRequired,
};

export default ParticipantButton;