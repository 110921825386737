import React from 'react';
import Loader from './Loader';

const OverlayLoader = ({ show }) => {
  if (!show) return null;

  return (
    <div className="iframe-pre-loader">
      <Loader />
    </div>
  );
};

export default OverlayLoader;