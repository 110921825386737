import React from 'react';

const ParticipantFilter = ({ filterValue, handleFilterChange }) => (
  <div className="participants-filter">
    <input
      className=""
      id="filter-id"
      name=""
      placeholder="Search for Participants"
      type="text"
      value={filterValue}
      onChange={handleFilterChange}
    />
  </div>
);

export default ParticipantFilter;