import React from 'react';

const AcceptRequestNotification = () => {
    return (
        <div className="toaster-wrapper">
            <div className="toaster-content-area">
                <div className="toaster-title">You have accepted the request to talk</div>
                <div className="toaster-content">
                    <div className="toaster-content-text">
                        <span>You will be able to talk in a while.</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AcceptRequestNotification;