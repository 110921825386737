import React from 'react';

const LeaveDialog = ({ isOpen, onConfirm, onCancel }) => {
    if (!isOpen) return null;

    return (
        <div className="dialog-overlay">
            <div className="dialog-box">
                <p>Do you really want to leave the meeting?</p>
                <button className="no-button" onClick={onCancel}>
                    No, stay in the meeting
                </button>
                <button onClick={onConfirm}>
                    Yes, leave meeting
                </button>
            </div>
        </div>
    );
};

export default LeaveDialog;