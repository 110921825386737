import React, {useRef, useState, useEffect, useLayoutEffect} from 'react';
import ReactPlayer from 'react-player';


function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}


function StreamPlayer({streamUrl, participant, chat, onPlayerReady}) {

    const playerRef = useRef(null);
    const [lastResetToLive, setLastResetToLive] = useState(Date.now());

    const [width, height] = useWindowSize();


    // useEffect(() => {
    //     const handleResize = () => {
    //         setWidth(window.innerWidth);
    //     };
    //
    //     window.addEventListener('resize', handleResize);
    //
    //     window.removeEventListener('resize', handleResize);
    // }, []);

    const handleBufferEnd = () => {
        if (Date.now() - lastResetToLive > 2000) {
            playerRef.current.seekTo('live');
            setLastResetToLive(Date.now());
        }
    };

    const handlePlayerReady = () => {
        //console.log('ReactPlayer is ready');
        onPlayerReady();
    };

    return (

        <ReactPlayer
            ref={playerRef}
            url={streamUrl}
            className={'videoPlayer'}
            playing
            controls={false}
            onBufferEnd={handleBufferEnd}
            onReady={handlePlayerReady}
            width={ participant || chat ? (width - 340)  : (height - 70) * 1.775}
            height={ participant || chat ? (width - 341)/1.775  : (height - 71)}
        />
    );
}

export default StreamPlayer;
