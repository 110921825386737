import React, {useState, useEffect} from 'react';

function DeclineRequestNotification(props) {

    const [showToaster, setShowToaster] = useState(true);


    useEffect(() => {
        setTimeout(() => {
            toasterClose();
        }, 4000);
    }, [showToaster]);

    function toasterClose() {
        setShowToaster(false);
        props.closeToaster();
    }


    return (
        <div className="toaster-wrapper reject">
            <span className="toaster-close">
                <svg onClick={() => toasterClose()} height="22" width="22" viewBox="0 0 24 24">
                    <path
                        d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
                    </svg>
                </span>

            <div className="toaster-icon">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16 0C12.8355 0 9.74207 0.938384 7.11088 2.69649C4.4797 4.45459 2.42894 6.95345 1.21793 9.87707C0.0069325 12.8007 -0.309921 16.0177 0.307443 19.1214C0.924806 22.2251 2.44866 25.0761 4.6863 27.3137C6.92394 29.5513 9.77487 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.1229 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C31.9952 11.758 30.308 7.6911 27.3085 4.69155C24.3089 1.692 20.242 0.00476422 16 0ZM22.514 20.939L20.939 22.514L16 17.575L11.06 22.514L9.48601 20.939L14.426 16L9.48701 11.061L11.061 9.486L16 14.426L20.939 9.487L22.514 11.062L17.575 16L22.514 20.939Z"
                        fill="#E7453C"/>
                </svg>
            </div>

            <div className="toaster-content-area">
                <div className="toaster-title">{props.message}</div>
                <div className="toaster-content">The host has declined your request to talk.</div>
            </div>
        </div>
    )
}

export default DeclineRequestNotification;
