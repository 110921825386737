import React from 'react';

const ParticipantHeader = ({ onClose }) => (
  <div className="participants-header">
    <div className="participants-header-content">Participants</div>
    <svg onClick={onClose} height="22" width="22" viewBox="0 0 24 24">
      <path d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
    </svg>
  </div>
);

export default ParticipantHeader;