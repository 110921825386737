import React from 'react';

const HangupButton = ({ onClick }) => {
  return (
    <div className="audio-preview">
      <div className="p-2 bd-highlight">
        <button className="hangup-button" onClick={onClick}>
          <div>
            <div>
              <div className="jitsi-icon jitsi-icon-default">
                <svg height="22" width="22" viewBox="0 0 29.22 9.581">
                  <g data-name="Group 6763">
                    <path
                      data-name="Path 3012"
                      d="M29.22 7.171v1.4a.953.953 0 01-.9 1h-7.74a.952.952 0 01-.89-1v-1c-.01-.56-.02-1.11-.02-1.67.01 0 .01-.01.01-.02a.239.239 0 00-.21-.24 19.905 19.905 0 00-9.52 0h-.02a.247.247 0 00-.2.28c0 .41-.01.84-.02 1.26H9.7v1.6a.926.926 0 01-.88.8H.9a.953.953 0 01-.9-1v-3.62c0-1.97 1.11-2.49 2.98-3.11a37.354 37.354 0 0123.44.07c1.63.56 2.74 1.04 2.78 2.77.02.83.02 1.65.02 2.48z"
                      fill="#f24834"
                    ></path>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
};

export default HangupButton;