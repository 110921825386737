import React from 'react';
import { useSelector } from 'react-redux';
import ParticipantItem from './ParticipantItem';

const ParticipantList = ({ localUsername, userId, getAvatarColor, raisedHands, screenPresenters }) => {
  const guests = useSelector(state => state.participants.guests);
  const hosts = useSelector(state => state.participants.hosts);
  const presenters = useSelector(state => state.participants.presenters);

  const sortedFilteredHosts = Object.entries(hosts).sort((a, b) => a[1].localeCompare(b[1]));
  const sortedFilteredPresenters = Object.entries(presenters).sort((a, b) => a[1].localeCompare(b[1]));
  const sortedFilteredGuests = Object.entries(guests).sort((a, b) => a[1].localeCompare(b[1]));

  return (
    <div className="participant-tab">
      <div className="participant-joined">
        Joined
        <span className="participant-joined-counter">
          {Object.entries(guests).length + Object.entries(hosts).length + Object.entries(presenters).length}
        </span>
      </div>

      <div className="participant-list">
        <ParticipantItem
          key={userId}
          id={userId}
          name={localUsername}
          role="You as Guest"
          getAvatarColor={getAvatarColor}
        />

        {sortedFilteredHosts.map(([id, name]) => (
          <ParticipantItem
            key={id}
            id={id}
            name={name}
            role="Host"
            getAvatarColor={getAvatarColor}
            hasRaisedHand={raisedHands.has(id.replace(/^(mp-|wt-)/, ''))}
            isSharingScreen={screenPresenters.has(id.replace(/^(mp-|wt-)/, ''))}
          />
        ))}

        {sortedFilteredPresenters.map(([id, name]) => (
          <ParticipantItem
            key={id}
            id={id}
            name={name}
            role="Presenter"
            getAvatarColor={getAvatarColor}
            hasRaisedHand={raisedHands.has(id.replace(/^(mp-|wt-)/, ''))}
            isSharingScreen={screenPresenters.has(id.replace(/^(mp-|wt-)/, ''))}
          />
        ))}

        {sortedFilteredGuests
          .filter(([id]) => id !== userId)
          .map(([id, name]) => (
            <ParticipantItem
              key={id}
              id={id}
              name={name}
              role="Guest"
              getAvatarColor={getAvatarColor}
            />
          ))}
      </div>
    </div>
  );
};

export default ParticipantList;