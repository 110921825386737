import React from 'react';
import MessageContent from '../MessageContent';

const ChatMessage = ({ message, isSelf, getAvatarColor }) => {
  if (isSelf) {
    return (
      <li className="chat-message-self">
        <div className="chat-individual-message">
          <MessageContent content={message.content} />
        </div>
      </li>
    );
  }

  return (
    <li className="chat-message">
      <div className="chat-display-name">
        <i style={{backgroundColor: getAvatarColor(message.sender)}}>
          {message.name[0]}
        </i>
        <span>{message.name}</span>
      </div>
      <div className="chat-individual-message">
        <MessageContent content={message.content} />
      </div>
    </li>
  );
};

export default ChatMessage;