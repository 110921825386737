import { ADD_GUEST, DELETE_GUEST, ADD_HOST, DELETE_HOST, ADD_PRESENTER, DELETE_PRESENTER} from "./actionTypes.ts";

export const addGuest = (userId, userName) => ({
    type: ADD_GUEST,
    payload: { userId, userName },
});

export const deleteGuest = (userId) => ({
    type: DELETE_GUEST,
    payload: userId,
});

export const addHost = (userId, userName) => ({
    type: ADD_HOST,
    payload: { userId, userName },
});

export const deleteHost = (userId) => ({
    type: DELETE_HOST,
    payload: userId,
});

export const addPresenter = (userId, userName) => ({
    type: ADD_PRESENTER,
    payload: { userId, userName },
});

export const deletePresenter = (userId) => ({
    type: DELETE_PRESENTER,
    payload: userId,
});