import React, {useState, useEffect} from 'react';

function RequestTalkToaster(props) {

    const [showToaster, setShowToaster] = useState(true);


    useEffect(() => {
        setTimeout(() => {
            toasterClose();
        }, 4000);
    }, [showToaster]);

    function toasterClose() {
        setShowToaster(false);
        props.closeToaster();
    }


    return (
        <div className="toaster-wrapper">
            <span className="toaster-close">
                <svg onClick={() => toasterClose()} height="22" width="22" viewBox="0 0 24 24">
                    <path
                        d="M18.984 6.422L13.406 12l5.578 5.578-1.406 1.406L12 13.406l-5.578 5.578-1.406-1.406L10.594 12 5.016 6.422l1.406-1.406L12 10.594l5.578-5.578z"></path>
                    </svg>
                </span>

            <div className="toaster-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                    <path id="tick"
                          d="M16,30A14,14,0,1,1,30,16,14,14,0,0,1,16,30ZM16,4A12,12,0,1,0,28,16,12,12,0,0,0,16,4ZM14.66,20.75l9-8-1.32-1.5L14,18.63,9.71,14.29,8.29,15.71l5,5a1.006,1.006,0,0,0,1.37.04Z"
                          transform="translate(-2 -2)" fill="#365d7e"/>
                </svg>
            </div>

            <div className="toaster-content-area">
                <div className="toaster-title">Request Sent</div>
                <div className="toaster-content">The request has been successfully sent to the host.</div>
            </div>
        </div>
    )
}

export default RequestTalkToaster;