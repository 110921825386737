import React from 'react';
import Toaster from '../Toaster/Toaster';  // Adjust this import path as necessary

const MessageToaster = ({ show, onClose, sender, message }) => {
    if (!show) return null;

    return (
        <Toaster
            closeToaster={onClose}
            title={sender}
            content={message}
        />
    );
};

export default MessageToaster;