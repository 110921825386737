import React from "react";
import '../../ChatApp.css';

const formatCurrentDate = () => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const months = [
        "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
    ];
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return {
        date: `${days[now.getDay()]}, ${months[now.getMonth()]} ${now.getDate()}`,
        time: `${formattedHours}:${formattedMinutes} ${ampm}`
    };
};

function InfoContent({ roomName, bigMeetingInvitationURL }) {
    let guestTextToCopy = bigMeetingInvitationURL ? `${bigMeetingInvitationURL}/${roomName}` : "";

    const { date: meetingDate, time: meetingTime } = formatCurrentDate();
    console.log("date: ", meetingDate, ", time:", meetingTime);

    const [copied, setCopied] = React.useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(guestTextToCopy).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    return (
        <>
            <div id="information-preview-content">
                <div className="meeting meeting-credential">
                    <div className="meeting meeting-credential-title">
                        Meeting Time: <span className="meeting meeting-credential-value">{meetingTime}</span>
                    </div>
                    <div className="meeting meeting-credential-title">
                        Date: <span className="meeting meeting-credential-value">{meetingDate}</span>
                    </div>
                </div>
                <div className="meeting meeting-link-title">Meeting link:</div>
                <div className="meeting meeting-link-row">
                        <div className="meeting meeting-link-url">
                            {bigMeetingInvitationURL && bigMeetingInvitationURL.length > 28
                                ? `${bigMeetingInvitationURL.substring(0, 29)}...`
                                : bigMeetingInvitationURL}
                        </div>
                        <button className="meeting meeting-link-copy-button" onClick={handleCopy}>
                            {copied ? "Copied!" : "Copy"}
                        </button>
                </div>
            </div>
        </>
    );
}

export default InfoContent;
