import React, { useState } from 'react';
import ReactionsMenu from './ReactionsMenu';

const EmojiReaction = ({ handleEmojiClick }) => {
    const [showReactionPanel, setShowReactionPanel] = useState(false);

    return (
        <div className="emoji-effect" title="Reactions">
            <div className="reactions-menu-popup-container">
                {showReactionPanel && (
                    <ReactionsMenu handleEmojiClick={handleEmojiClick} />
                )}

                <div className="reactions-menu-popup">
                    <div aria-label="Reactions" className="toolbox-button"
                        role="button" tabIndex="0"
                        onClick={() => {
                            setShowReactionPanel(!showReactionPanel)
                        }}
                    >
                        <div>
                            <div className="toolbox-icon ">
                                <div className="jitsi-icon jitsi-icon-default ">
                                    <svg height="22" width="22"
                                        viewBox="0 0 26.762 26.365">
                                        <g data-name="Group 4022"
                                            transform="translate(.8 .8)">
                                            <rect data-name="Rectangle 145"
                                                width="25.162" height="24.765"
                                                rx="10" fill="none"
                                                stroke="#c7ccd9"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.6"></rect>
                                            <path data-name="Path 50"
                                                d="M16.174 16.011c.248 0 .459.125.431.269-.226 1.2-1.941 2.131-4.023 2.131s-3.8-.932-4.023-2.131c-.028-.146.179-.269.431-.269z"
                                                fill="#d4dae0"></path>
                                            <path data-name="Line 19" fill="none"
                                                stroke="#c7ccd9"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.6"
                                                d="M8.968 8.747v2.325"></path>
                                            <path data-name="Line 20" fill="none"
                                                stroke="#c7ccd9"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="1.6"
                                                d="M16.009 8.747v2.325"></path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmojiReaction;