import React from 'react';

const ChatButton = ({ onClick, newMessageCounter, chatPanel }) => {
  return (
    <div className="chat-effect" onClick={onClick}>
      <div className="toolbar-button-with-badge">
        {!chatPanel && newMessageCounter > 0 && (
          <span className={'chat-count-label'}>{newMessageCounter}</span>
        )}
        <div
          aria-pressed="false"
          aria-disabled="false"
          aria-label="Open / Close chat"
          title="Open / Close chat"
          className="toolbox-button"
          tabIndex="0"
          role="button"
        >
          <div>
            <div className="toolbox-icon">
              <div className="jitsi-icon jitsi-icon-default">
                <svg height="22" width="22" viewBox="0 0 26.007 27.501">
                  <g data-name="Group 187">
                    <g
                      data-name="Group 186"
                      transform="translate(.8 .8)"
                      fill="none"
                      stroke="#c7ccd9"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.6"
                    >
                      <path data-name="Line 12" d="M6.659 7.354h10.326"></path>
                      <path data-name="Line 13" d="M6.452 13.319h5.318"></path>
                      <path data-name="Line 14" d="M12.005 25.578l3.121-3.966"></path>
                      <rect
                        data-name="Rectangle 144"
                        width="24.407"
                        height="21.472"
                        rx="4.185"
                      ></rect>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <span className="badge-round">
          <span></span>
        </span>
      </div>
    </div>
  );
};

export default ChatButton;