import React from 'react';

const ParticipantItem = ({ id, name, role, getAvatarColor, hasRaisedHand, isSharingScreen }) => (
  <div className="single-participant">
    <div 
      className="participant-avatar"
      style={{ background: getAvatarColor(id), cursor: 'pointer' }}
    >
      {name[0]}
    </div>
    <div className="participant-name">
      {name}
      <span>{role}</span>
      <div className="participant-icons">
        { hasRaisedHand && (
          <img src={require('../../images/svg/raisedHand.svg').default} alt="Raised Hand" className="raised-hand-icon"/>
        )}
        { isSharingScreen && (
          <img src={require('../../images/svg/screenShare.svg').default} alt="Screen Share" className="screen-share-icon"/>
        )}
      </div>
    </div>
  </div>
);

export default ParticipantItem;