import React from 'react';

const emojiMap = {
    ':thumbs_up:': '👍',
    ':clap:': '👏',
    ':grinning_face:': '😀',
    ':face_with_open_mouth:': '😮',
    ':slightly_frowning_face:': '🙁',
    ':face_without_mouth:': '😶',
};

const ReactionsMenu = ({ handleEmojiClick }) => {
    return (
        <div className="reactions-menu">
            <div className="reactions-row">
                <div aria-label="Thumbs Up" aria-pressed="false"
                    className="toolbox-button" role="button"
                    tabIndex="0">
                    <div>
                        <div className="toolbox-icon " onClick={() => handleEmojiClick(emojiMap[':thumbs_up:'])}><span
                            className="emoji increase-0">{emojiMap[':thumbs_up:']}</span></div>
                    </div>
                </div>
                <div aria-label="Clap" aria-pressed="false"
                    className="toolbox-button" role="button"
                    tabIndex="0" onClick={() => handleEmojiClick(emojiMap[':clap:'])}>
                    <div>
                        <div className="toolbox-icon "><span
                            className="emoji increase-0">{emojiMap[':clap:']}</span></div>
                    </div>
                </div>
                <div aria-label="Laugh" aria-pressed="false"
                    className="toolbox-button" role="button"
                    tabIndex="0" onClick={() => handleEmojiClick(emojiMap[':grinning_face:'])}>
                    <div>
                        <div className="toolbox-icon "><span
                            className="emoji increase-0">{emojiMap[':grinning_face:']}</span></div>
                    </div>
                </div>
                <div aria-label="Surprised" aria-pressed="false"
                    className="toolbox-button" role="button"
                    tabIndex="0" onClick={() => handleEmojiClick(emojiMap[':face_with_open_mouth:'])}>
                    <div>
                        <div className="toolbox-icon "><span
                            className="emoji increase-0">{emojiMap[':face_with_open_mouth:']}</span></div>
                    </div>
                </div>
                <div aria-label="Boo" aria-pressed="false"
                    className="toolbox-button" role="button"
                    tabIndex="0" onClick={() => handleEmojiClick(emojiMap[':slightly_frowning_face:'])}>
                    <div>
                        <div className="toolbox-icon "><span
                            className="emoji increase-0">{emojiMap[':slightly_frowning_face:']}</span></div>
                    </div>
                </div>
                <div aria-label="Silence" aria-pressed="false"
                    className="toolbox-button" role="button"
                    tabIndex="0" onClick={() => handleEmojiClick(emojiMap[':face_without_mouth:'])}>
                    <div>
                        <div className="toolbox-icon "><span
                            className="emoji increase-0">{emojiMap[':face_without_mouth:']}</span></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReactionsMenu;