import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import VideoPlayer from './VideoPlayer.js';
import StreamPlayer from './StreamPlayer.js';
import Layout from "./components/Layout/Layout";
import { useDispatch } from 'react-redux';
import { deleteHost, deletePresenter } from './redux/Participants/actions.js';

var sendToMp

function App() {
  const dispatch = useDispatch();
  const [mpUrl,setMpUrl]=useState(null)
  const [showIframe, setShowIframe] = useState(true);
  const [username, setUsername] = useState(localStorage.getItem('username') || '');
  const [userId, setUserId] = useState(() => {
    return localStorage.getItem('userId') || Math.random().toString(36).substring(2, 12);
  });
  const [isRecent, setIsRecent] = useState(false);
  const [leaveLink, setLeaveLink] = useState('');
  
  const leaveLinkRef = React.useRef(leaveLink);

  useEffect(() => {
    localStorage.setItem('userId', userId);
  }, [userId]);

  useEffect(() => {
    leaveLinkRef.current = leaveLink;
  }, [leaveLink]);

  const setRecent = (value) => {
    setIsRecent(value);
  };

  const updateCredentials = (newUsername, roomName) => {
    console.log('Inside updateCredentials => newUsername ::', newUsername);
    
    setUsername(newUsername);
    
    const roomCredentials = { username: newUsername };
    localStorage.setItem(`room-${roomName}`, JSON.stringify(roomCredentials));

    // Create and save the leaveLink
    const newLeaveLink = `${process.env.REACT_APP_FEEDBACK_URL}?meetingId=${roomName}`;
    setLeaveLink(newLeaveLink);
  };

  const handleEndMeeting = () => {
    setShowIframe(false);
    setMpUrl(null);
    setIsRecent(true);
    
    // Clear local storage
    localStorage.clear();
    
    // Reset state variables
    setUsername('');
    //alert('Meeting ended successfully');
    window.location.href = leaveLinkRef.current;
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data && event.data.action === 'closeIframe') {
        setShowIframe(false);
        setMpUrl(null);
        setIsRecent(true);

        if (event.data.userId) {
          setUserId(event.data.userId);
          localStorage.setItem('userId', event.data.userId);
          dispatch(deleteHost(event.data.meetingPanelId));
          dispatch(deletePresenter(event.data.meetingPanelId));
        }
      } else if (event.data && event.data.action === 'endMeeting') {
        handleEndMeeting();
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [dispatch]);

  sendToMp=(url)=>{
    //url = url.replace('dev-bm.convay.com', 'localhost:8080'); // for testing purpose, because, iframe is loaded from this url, if not localhost, cannot see local changes
    setMpUrl(url);
    setShowIframe(true);
  }

  return(
    <>
      {
        mpUrl===null && <Router>
        <Routes>
          {/*<Route path="/VideoPlayer" element={<VideoPlayer />} />*/}
          {/*<Route path="/StreamPlayer" element={<StreamPlayer />} />*/}
          <Route path="/" element={
            <Layout 
                  userId={userId} 
                  username={username}
                  updateCredentials={updateCredentials} 
                  isRecent={isRecent} 
                  setRecent={setRecent}/>} 
            />
          <Route path="/:roomName" element={
            <Layout 
                  userId={userId} 
                  username={username}
                  updateCredentials={updateCredentials} 
                  isRecent={isRecent} 
                  setRecent={setRecent}/>} />
        </Routes>
      </Router>
      }
      <div>
      {showIframe && (
        <iframe
          title="MeetingPanel"
          style={{
          width:'100vw',
          height:'100vh',
          padding:'0px',
          margin:'0px',
          border:'none',
          display:mpUrl!==null?'block':'none' 
        }} allow="camera; microphone; display-capture; autoplay; clipboard-write;" 
        // need to specify the domain for the dummy meeting dynamically here
        src={mpUrl ? mpUrl : `${process.env.REACT_APP_DUMMY_MEETING_URL}`}/>
      )}
      </div>
    </>
  )
}

export default App;
export {sendToMp}